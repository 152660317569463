/**
 * Formats error message to be more user friendly
 * @param errorMsg
 * @returns formatted error message
 */
export default function modifyErrorMessage(errorMsg) {
    if (errorMsg.includes('Cannot deserialize value of type `java.lang.Number`')) {
        const match = errorMsg.match(/"([^"]+)": not a valid number/);
        const invalidNumber = match ? match[1] : 'an invalid number';
        return `${invalidNumber} is not a valid number.`;
    }
    return errorMsg;
}
