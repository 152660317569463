import { css } from '@emotion/core';
import Notification from '@veeva/corgix/notification';
import { faHourglassEnd } from '@fortawesome/pro-regular-svg-icons';
import modifyErrorMessage from './modifyErrorMessage';

export function showUnexpectedErrorNotification() {
    Notification.error({
        title: 'Unexpected Error',
        message: 'Something went wrong, please try again',
        placement: 'topright',
        persist: true,
    });
}

export function showInternalErrorNotification() {
    Notification.error({
        title: 'Internal Error',
        message: 'Sorry, an internal error has occurred',
        placement: 'topright',
        persist: true,
    });
}

export function showInternalErrorWithUUIDNotification(uuid) {
    Notification.error({
        title: 'Internal Error',
        message: (
            <>
                <p>Sorry, an internal error has occurred</p>
                <strong>UUID: {uuid}</strong>
            </>
        ),
        placement: 'topright',
        persist: true,
    });
}

export function showAPINotImplementedNotification() {
    Notification.error({
        title: 'Error',
        message: 'API Not Implemented',
        placement: 'topcenter',
    });
}

export function showExportExceedsLimitNotification() {
    Notification.error({
        title: 'Unable to export',
        message:
            'Unable to export due to maximum 100,000 rows reached. Try again with a smaller date range.',
        placement: 'topright',
    });
}

export function showSessionExpiredNotification() {
    Notification.custom({
        title: 'Your session has expired',
        message: 'Please login again.',
        placement: 'topright',
        customIcon: faHourglassEnd,
    });
}

export function showInvalidFilterNameNotification(pendingFilterName) {
    Notification.warn({
        title: 'Invalid Filter Name',
        message: `Invalid filter name "${pendingFilterName}" already exists.`,
        size: 'small',
        placement: 'topcenter',
    });
}

export function showSuccessInactivateCustomer(messageDetails) {
    Notification.basic({
        message: messageDetails,
        placement: 'topcenter',
        transient: false,
        style: {
            backgroundColor: '#e0f3e0',
            width: '80%',
            maxWidth: '35rem',
        },
    });
}

export function showSuccessRemoveInstance(messageDetails) {
    Notification.basic({
        message: messageDetails,
        placement: 'topcenter',
        transient: false,
        style: {
            backgroundColor: '#e0f3e0',
            width: '80%',
            maxWidth: '35rem',
        },
    });
}

const wordBreakCSS = css`
    word-break: break-all;
`;

export function showErrorNotificationWithMessage(message) {
    Notification.error({
        title: 'Error',
        message: <span css={wordBreakCSS}>{modifyErrorMessage(message)}</span>,
        placement: 'topright',
        persist: true,
        labels: {
            more: 'Show more',
            less: 'Show less',
        },
    });
}

export function showErrorNotificationForBatchCustomerUpdate(errorMsg) {
    Notification.error({
        message: errorMsg,
        title: 'Error',
        placement: 'topright',
        persist: true,
    });
}

export function showErrorNotificationForCustomerUpdate(customerId) {
    Notification.error({
        message: `Customer ${customerId} not inactivated`,
        title: 'Error',
        persist: true,
    });
}

export function showErrorNotificationForExport() {
    Notification.error({
        message: `Error when exporting to Excel`,
        title: 'Error',
        placement: 'topright',
        persist: true,
    });
}
